import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import Layout from "../../zzz/layout"
import SEO from "../../components/SEO/seo"
import Heading from "../../zzz/organisms/heading"
import Inner from "../../zzz/layout/pageInner/Inner"
import ReactPlayer from "react-player/youtube"
import SvgIcon from "../../zzz/atoms/icons/svg-icon"
import StarSolid from "../../zzz/atoms/icons/files/star-solid.jsx"
import LinkWrapper from "../../utils/linkWrapper"
import { getImage } from "../../lib/util"
import { GatsbyImage } from "gatsby-plugin-image"
import IconColor100Nights from "../../zzz/atoms/icons/color/icon-color-100-night-trial"
import IconColorFastDelivery from "../../zzz/atoms/icons/color/icon-color-fast-delivery"
import IconColorGuarantee from "../../zzz/atoms/icons/color/icon-color-guantantee"
import IconColorReturns from "../../zzz/atoms/icons/color/icon-color-returns"

const HospitalityBedsAndMattressesPage = ({ location }) => {
  const data = useStaticQuery(PAGE)

  const { businessToBusiness, content, heading, featuredImage, seo } =
    data.hospitalityBedsAndMattressesPageData
  const mainContentArea = businessToBusiness.b2bMainContentArea
  const guaranteesArea = businessToBusiness.b2bOurGuarantees
  const registerArea = businessToBusiness.b2bRegisterArea
  const hospitalitiesArea = businessToBusiness.b2bHospitalitiesArea

  var scriptContainerHTML = ""
  if (businessToBusiness.b2bVideo) {
    scriptContainerHTML = {
      __html: `
    <script type="application/ld+json">
      {
        "@context": "https://schema.org/",
        "@type": "VideoObject",
        "name": "${businessToBusiness.b2bVideoName}",
        "thumbnailUrl": "${businessToBusiness.b2bVideoThumbnail.sourceUrl}",
        "uploadDate": "${businessToBusiness.b2bVideoUploadDate}"
      }
    </script>
  `,
    }
  }

  return (
    <Layout location={location}>
      {/* START - SEO Area */}
      <SEO title="Contact Us" location={location} post_id={90} seo={seo} />
      {/* END - SEO Area */}

      {/* START - Header Area */}
      <Heading
        location={location}
        subTitle={heading.subTitle}
        image={getImage(featuredImage)}
      />
      {/* END - Header Area */}

      {/* START - Main Content Area */}
      {mainContentArea.title &&
        mainContentArea.firstSection &&
        mainContentArea.secondSection &&
        mainContentArea.belowCopy && (
          <Wrap className="mainContentArea">
            {mainContentArea.title && (
              <Inner className="center">
                <h2>{mainContentArea.title}</h2>
              </Inner>
            )}
            {mainContentArea.firstSection.leftSideImage &&
              mainContentArea.firstSection.rightSideCopy && (
                <Inner className="two_columns">
                  <div>
                    <GatsbyImage
                      image={
                        mainContentArea.firstSection.leftSideImage.imageFile
                          .localFile.childImageSharp.gatsbyImageData
                      }
                      className="mainContentLeftSideImage"
                    />
                  </div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: mainContentArea.firstSection.rightSideCopy,
                    }}
                  />
                </Inner>
              )}
            {mainContentArea.secondSection.leftSideCopy &&
              mainContentArea.secondSection.rightSideImage && (
                <Inner className="two_columns">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: mainContentArea.secondSection.leftSideCopy,
                    }}
                  />
                  <div>
                    <GatsbyImage
                      image={
                        mainContentArea.secondSection.rightSideImage.imageFile
                          .localFile.childImageSharp.gatsbyImageData
                      }
                      className="mainContentLeftSideImage"
                    />
                  </div>
                </Inner>
              )}
            {mainContentArea.belowCopy && (
              <Inner>
                <div
                  dangerouslySetInnerHTML={{
                    __html: mainContentArea.belowCopy,
                  }}
                />
              </Inner>
            )}
          </Wrap>
        )}
      {/* END - Main Content Area */}

      {/* START - Guarantee Area */}
      {guaranteesArea.title && guaranteesArea.guarantees && (
        <Background>
          <Wrap className="guaranteeArea">
            {guaranteesArea.title && (
              <Inner className="center">
                <h3
                  className="mb-0"
                  dangerouslySetInnerHTML={{ __html: guaranteesArea.title }}
                />
              </Inner>
            )}
            {guaranteesArea.title && guaranteesArea.subTitle && (
              <Inner className="center">
                {guaranteesArea.subTitle && (
                  <div
                    className="subtitle"
                    dangerouslySetInnerHTML={{
                      __html: guaranteesArea.subTitle,
                    }}
                  />
                )}
              </Inner>
            )}
            {guaranteesArea.guarantees && (
              <Inner>
                <Guarantees guarantees={guaranteesArea.guarantees} />
              </Inner>
            )}
          </Wrap>
        </Background>
      )}
      {/* END - Guarantee Area */}

      {/* START - Register Content Area */}
      {registerArea.topContent && registerArea.button && (
        <Wrap className="registerContentArea">
          {registerArea.topContent && (
            <Inner>
              <div
                dangerouslySetInnerHTML={{ __html: registerArea.topContent }}
              />
            </Inner>
          )}
          {registerArea.button && (
            <Inner className="center">
              <Button
                href={registerArea.button.url}
                target={registerArea.button.target}
              >
                <div>{registerArea.button.title}</div>
                <SvgIcon
                  SvgComponent={StarSolid}
                  hue="danger"
                  size="md"
                  shade="fontColor"
                />
              </Button>
            </Inner>
          )}
          {registerArea.button && (
            <Inner className="center">
              <div className="buttonButtomText">
                **Verification will be done within 24 hours during business
                days.
              </div>
            </Inner>
          )}
        </Wrap>
      )}
      {/* END - Register Content Area */}

      {/* START - Hospitalities Area */}
      {hospitalitiesArea.logos && (
        <Background>
          <Wrap className="hospitalitiesArea">
            {hospitalitiesArea.title && (
              <Inner className="center">
                <h3
                  className={hospitalitiesArea.subTitle ? "mb-1" : "mb-2"}
                  dangerouslySetInnerHTML={{ __html: hospitalitiesArea.title }}
                />
              </Inner>
            )}
            {hospitalitiesArea.title && hospitalitiesArea.subTitle && (
              <Inner className="mb-2 center">
                <div
                  className="subtitle text-center"
                  dangerouslySetInnerHTML={{
                    __html: hospitalitiesArea.subTitle,
                  }}
                />
              </Inner>
            )}
            {hospitalitiesArea.logos && (
              <Logos logos={hospitalitiesArea.logos} />
            )}
          </Wrap>
        </Background>
      )}
      {/* END - Hospitalities Area */}

      {/* START - Video Area */}
      {scriptContainerHTML && (
        <SchemaContainer
          dangerouslySetInnerHTML={scriptContainerHTML}
        ></SchemaContainer>
      )}
      {businessToBusiness.b2bVideo && (
        <Wrap className="videoArea">
          <Inner className="fullwidth">
            <ReactPlayer
              width="100%"
              height="650px"
              style={{ margin: "0 auto" }}
              url={businessToBusiness.b2bVideo}
            />
          </Inner>
        </Wrap>
      )}
      {/* END - Video Area */}
    </Layout>
  )
}

const Logos = ({ logos }) => {
  return (
    <Inner>
      <Grid>
        {logos &&
          logos.map((logo, idx) => {
            const image = (
              <GatsbyImage
                image={
                  logo.logo.imageFile.localFile.childImageSharp.gatsbyImageData
                }
                key={idx}
                alt={`logo-${idx}`}
              />
            )

            // wrap with link if there is one
            if (logo.logoLinkUrl && logo.logoLinkUrl !== "#") {
              return (
                <LinkWrapper key={idx} to={logo.logoLinkUrl} target="_blank">
                  {image}
                </LinkWrapper>
              )
            }
            // only return image
            return image
          })}
      </Grid>
    </Inner>
  )
}

const Guarantees = ({ guarantees }) => {
  return (
    <Inner>
      <Grid className="guaranteeBlockGrid">
        {guarantees &&
          guarantees.map((guarantee, idx) => {
            const icon = guarantee.icon
            const title = guarantee.title
            const description = guarantee.description

            return (
              <GuaranteeBlock key={idx}>
                <div className="guaranteeBlockInner">
                  {icon.title == "fast-delivery" && (
                    <div className="guaranteeBlockIcon">
                      <IconColorFastDelivery />
                    </div>
                  )}
                  {icon.title == "100-night-trial" && (
                    <div className="guaranteeBlockIcon">
                      <IconColor100Nights />
                    </div>
                  )}
                  {icon.title == "fast-delivery copy" && (
                    <div className="guaranteeBlockIcon">
                      <IconColorReturns />
                    </div>
                  )}
                  {icon.title == "lowest-price" && (
                    <div className="guaranteeBlockIcon">
                      <IconColorGuarantee />
                    </div>
                  )}
                  <div className="guaranteeBlockTitle">{title}</div>
                  <div className="guaranteeBlockSubTitle">{description}</div>
                </div>
              </GuaranteeBlock>
            )
          })}
      </Grid>
    </Inner>
  )
}

export default HospitalityBedsAndMattressesPage

const SchemaContainer = styled.div``

const Background = styled.div`
  background: #f0f1f3;
`

const Wrap = styled.div`
  padding: 60px 0;

  .blue {
    font-size: 20px;
    p {
      font-size: 21px;
    }
  }

  h2 {
    border-bottom: 2px solid red;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    margin-bottom: 40px;
  }

  h3 {
    border-bottom: 2px solid red;
    margin-bottom: 20px;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    max-width: 100%;
    margin-top: 15px;
  }

  ul {
    margin-top: 10px;
    margin-bottom: 10px;
    list-style: disc;
    padding-left: 17px;
    li {
    }
  }

  .mb-0 {
    margin-bottom: 0 !important;
  }

  .mb-1 {
    margin-bottom: 10px !important;
  }

  .mb-2 {
    margin-bottom: 20px !important;
  }

  .subtitle {
    color: #223247;
    font-size: 16px;
    margin-top: 10px;
    font-weight: 300;
    line-height: 1.5;
    font-style: normal;
  }

  .text-center {
    text-align: center;
  }

  .inner {
    &.center {
      display: flex;
      justify-content: center;
    }
    &.two_columns {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 40px;
      align-items: center;

      @media (max-width: ${({ theme }) => theme.breakSmall}) {
        grid-template-columns: repeat(1, 1fr);
      }
      &.fullwidth {
        display: block;
      }
    }
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 30px;
  padding: 40px 0;
  align-items: center;
  justify-items: center;
  img {
    mix-blend-mode: multiply;
  }
  @media (max-width: 1200px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    grid-template-columns: 1fr;
  }
  &.guaranteeBlockGrid {
    grid-template-columns: repeat(4, 1fr);
    align-items: start;
    @media (max-width: ${({ theme }) => theme.breakSmall}) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 600px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: ${({ theme }) => theme.breakTiny}) {
      grid-template-columns: 1fr;
    }
  }
`

const GuaranteeBlock = styled.div`
  .guaranteeBlockInner {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 15px 10px;
    .guaranteeBlockIcon {
      min-height: 72px;
      display: flex;
      align-content: center;
      justify-content: center;
      align-items: center;
    }
    .guaranteeBlockTitle {
      font-weight: 500;
    }
    .guaranteeBlockSubTitle {
      text-align: center;
      font-weight: 300;
    }
  }
`

// const StyledAboutUsCta = styled.div`
//   font-size: 2.4375rem;
//   font-weight: 300;
//   line-height: 1.5;
//   font-style: normal;
// `

// const Cta = styled.div`
//   background: #f6f6f6;
//   padding: 60px 0;
//   .inner {
//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     justify-content: space-between;
//   }
// `

const Button = styled.a`
  background: #fd3237;
  border-radius: 10px;
  color: white;
  text-transform: uppercase;
  display: flex;
  overflow: hidden;
  align-items: center;
  transition: 0.2s all ease-in-out;
  padding: 10px 50px;
  font-size: 20px;
  margin: 30px 0 20px;
  &:hover {
    transform: translateX(2px);
  }

  //   > div {
  //     padding: 0 15px;
  //     font-size: 20px;
  //   }

  //   > span {
  //     padding: 8px;
  //     background: #da2e32;
  //   }
  //
`

const PAGE = graphql`
  {
    hospitalityBedsAndMattressesPageData: localWpGraphQlPages(
      slug: { eq: "hospitality-beds-and-mattresses" }
    ) {
      seo {
        yoastTitle
        yoastDescription
        yoastMeta
        yoastSchema
        woocommerceSchema
      }
      heading {
        subTitle
      }
      content
      businessToBusiness {
        b2bMainContentArea {
          title
          firstSection {
            leftSideImage {
              sourceUrl
              title
              altText
              modified
              mediaItemId
              imageFile {
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED)
                  }
                }
              }
            }
            rightSideCopy
          }
          secondSection {
            leftSideCopy
            rightSideImage {
              sourceUrl
              title
              altText
              modified
              mediaItemId
              imageFile {
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED)
                  }
                }
              }
            }
          }
          belowCopy
        }
        b2bOurGuarantees {
          title
          guarantees {
            icon {
              sourceUrl
              title
              modified
              mediaItemId
            }
            title
            description
          }
        }
        b2bRegisterArea {
          topContent
          button {
            title
            url
            target
          }
        }
        b2bHospitalitiesArea {
          title
          subTitle
          logos {
            logo {
              sourceUrl
              title
              altText
              modified
              mediaItemId
              imageFile {
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED)
                  }
                }
              }
            }
            logoLinkUrl
          }
        }
        b2bVideo
        b2bVideoName
        b2bVideoThumbnail {
          sourceUrl
        }
        b2bVideoUploadDate
      }
      featuredImage {
        sourceUrl
        mediaItemId
        imageFile {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
    }
  }
`
